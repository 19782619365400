import { BrowserRouter as Router, Route } from 'react-router-dom';

import { FeaturesRouter } from './routers/FeaturesRouter';

import './App.scss';

function App() {
  return (
    <Router>
      <Route component={FeaturesRouter} />
    </Router>
  );
}

export default App;
