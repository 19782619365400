import React, { Component } from "react";
import './Home.scss';

export class Home extends Component {
    constructor(props){
        super(props)  
    }

    render() {
        return (
            <div className="home-container">
                <img src={`${process.env.PUBLIC_URL}/static/images/Logo_with_title.png`} alt="logo" />
            </div>
        );
    }
}