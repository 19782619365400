import { useEffect, useState } from "react";
import { Form, Spinner } from 'react-bootstrap';

import { Validators } from "../../../shared/utils/Validators";
import { SelectInput, TextInput } from "../../../shared/form/FormInput";
import { departmentList, locationList, roleList, userAdd, userDetail, userUpdate } from "../../../shared/settings/apiConfig";
import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";

import './ClientAddEdit.scss';

export const ClientAddEdit = (props) => {

    const isEdit = props.location.pathname.includes('edit');
    const [userId, setUserId] = props.location.pathname.includes('add') ? '' : props?.match?.params?.id;

    const [clientForm, setClientForm] = useState({
        firstname: { value: '', valid: false, error: '', validators: [Validators.required()] },
        lastname: { value: '', valid: false, error: '', validators: [Validators.required()] },
        locationid: { value: '', valid: false, error: '', validators: [Validators.required()] },
        mobile: { value: '', valid: false, error: '', validators: [Validators.required()] },
        email: { value: '', valid: false, error: '', validators: [Validators.required()] },
        roleid: { value: '', valid: false, error: '', validators: [Validators.required()] },
        departmentid: { value: '', valid: false, error: '', validators: [Validators.required()] },
        designation: { value: '', valid: false, error: '', validators: [Validators.required()] },
    });

    const [formLoading, setFormLoading] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [roleListData, setRoleListData] = useState('');
    const [locationListData, setLocationListData] = useState('');
    const [departmentListData, setDepartmentListData] = useState('');

    useEffect(() => {
        setFormLoading(true);
        Promise.all([getRolesList(), getLocationsList(), getDepartmentList()]).then(responses => {
            let roleData = responses[0];
            let locationData = responses[1];
            let departmentData = responses[2];
            setRoleListData(
                roleData.map(elem => {
                    return { value: elem.id, option: elem.name }
                }));
            setLocationListData(
                locationData.map(elem => {
                    return { value: elem.id, option: elem.title }
                }));
            setDepartmentListData(
                departmentData.map(elem => {
                    return { value: elem.id, option: elem.title }
                }));
            setFormLoading(false);
        })
        
        if (isEdit) {
            getApi(`${userDetail}/${userId}`).then((response) => {
                let data = response.data.data[0];
                if (data) {
                    setClientForm(prevState => {
                        return Validators.validateAndSetAllFormControls(clientForm, data)
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
        }
    }, []);

    useEffect(() => {
        setFormValid(Validators.validateForm(clientForm));
    }, [clientForm]);

    const inputChange = (type, event) => {
        setClientForm(prevState => {
            return {
                ...prevState,
                [type]: Validators.validateFormControl(prevState[type], event.target.value)
            }
        })
    };

    const getRolesList = () => {
        return new Promise((resolve, reject) => {
            getApi(roleList).then(response => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        })
    }

    const getLocationsList = () => {
        return new Promise((resolve, reject) => {
            getApi(locationList).then(response => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        })
    }

    const getDepartmentList = () => {
        let data = {
            "filter": []
        }
        return new Promise((resolve, reject) => {
            postApi(departmentList, {data}).then(response => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        })
    }

    const onCancel = () => {
        props.history.goBack();
    }

    const onSaveLocation = () => {
        setFormLoading(true);
        let data = {
            "firstname": clientForm.firstname.value,
            "lastname": clientForm.lastname.value,
            "email": clientForm.email.value,
            "mobile": clientForm.mobile.value,
            "locationid": clientForm.locationid.value,
            "roleid": clientForm.roleid.value,
            "departmentid": clientForm.departmentid.value,
            "designation": clientForm.designation.value
        };

        if (isEdit) {
            data["id"] = props.match.params.id
            putApi(userUpdate, { data }).then((response) => {
                if (response.data.status == "success") {
                    props.history.goBack();
                }
                setFormLoading(false);
            }).catch((error) => {
                setFormLoading(false);
                errorMessage(error.message[0]);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            })
        } else {
            postApi(userAdd, { data }).then((response) => {
                if (response.data.status == "success") {
                    props.history.goBack();
                }
                setFormLoading(false);
            }).catch((error) => {
                setFormLoading(false);
                setErrorMessage(error.message[0]);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            })
        }
    }

    return (
        <div className="display-flex h-center v-center mt-5 mb-5">
            <div className="client-box">
                <Form className="d-flex flex-column justify-content-center my-3">
                    <TextInput type={'text'} name={'firstname'} label={'FirstName'} placeholder={'First Name'}
                        value={clientForm.firstname.value} error={clientForm.firstname.error} onChange={(event) => inputChange("firstname", event)} />

                    <TextInput type={'text'} name={'lastname'} label={'LastName'} placeholder={'Last Name'}
                        value={clientForm.lastname.value} error={clientForm.lastname.error} onChange={(event) => inputChange("lastname", event)} />

                    <TextInput type={'text'} name={'email'} label={'Email'} placeholder={'Email'}
                        value={clientForm.email.value} error={clientForm.email.error} onChange={(event) => inputChange("email", event)} />

                    <TextInput type={'text'} name={'mobile'} label={'Mobile'} placeholder={'Mobile'}
                        value={clientForm.mobile.value} error={clientForm.mobile.error} onChange={(event) => inputChange("mobile", event)} />

                    <SelectInput name={'locationid'} label={'Location Title'} placeholder={'Location Title'}
                        value={clientForm.locationid.value} options={locationListData} error={clientForm.locationid.error} onChange={(event) => inputChange('locationid', event)} />

                    <SelectInput name={'departmentid'} label={'Department'} placeholder={'Department'}
                        value={clientForm.departmentid.value} options={departmentListData} error={clientForm.departmentid.error} onChange={(event) => inputChange('departmentid', event)} />

                    <TextInput type={'text'} name={'Designation'} label={'Designation'} placeholder={'Designation'}
                        value={clientForm.designation.value} error={clientForm.designation.error} onChange={(event) => inputChange("designation", event)} />

                    <SelectInput name={'roleid'} label={'Role Name'} placeholder={'Role Name'}
                        value={clientForm.roleid.value} options={roleListData} error={clientForm.roleid.error} onChange={(event) => inputChange('roleid', event)} />

                </Form>

                <div className="error-message">{errorMessage}</div>

                <div className="d-flex align-items-center justify-content-center mt-5" style={{ gap: '10px' }}>
                    <button type="button" className="btn btn-outline-primary" onClick={onCancel}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={onSaveLocation} disabled={!formValid}>Save {formLoading ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</button>
                </div>
            </div>
        </div>
    )
}