import React, { Component } from 'react';

// import store from '../../redux/store';

import Header from './Header';
import Sidebar from './Sidebar';
import { AppRouter } from '../../routers/AppRouter';

import './AppLayout.scss';

export default class Applayout extends Component {
    constructor(props){
        super(props)
        this.state = {
            path: '', 
        };
    }
    componentDidUpdate() {
        if (this.state.path !== this.props.history.location.pathname) {
            this.setState({ path: this.props.history.location.pathname });
            this.sidebarToggle();
        }
    }
    sidebarToggle = () => {
        // store.dispatch({ type: 'TOGGLE_SIDEBAR', payload: false });
    };

    render(){
        return(
            <div className='d-flex flex-column layout'>
                <div className='header-layout'>
                    <Header {...this.props} />
                </div>
                <div className='d-flex body-layout'>
                    <Sidebar {...this.props} />
                    <AppRouter {...this.props} />
                </div>
            </div>
        );
    }
}