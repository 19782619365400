export const assessmaxBaseurl = 'https://demo.assessmax.com/services';

export const loginApi = '/api/v1/auth/token';
export const refresh = '/api/v1/token/refresh';

export const userList = '/api/v1/user/list';
export const userDetail = '/api/v1/user/detail';
export const userAdd = '/api/v1/user/add';
export const userUpdate = '/api/v1/user/update';

export const timeZoneList = '/api/v1/timezone/list';
export const roleList = '/api/v1/role/list';

export const departmentList = '/api/v1/department/list';
export const departmentDetail = '/api/v1/department/detail/';
export const departmentAdd = '/api/v1/department/add';
export const departmentUpdate = '/api/v1/department/update';

export const locationList = '/api/v1/location/list';
export const locationDetail = '/api/v1/location/detail/';
export const locationAdd = '/api/v1/location/add';
export const locationUpdate = '/api/v1/location/update';

export const assessmentList = '/api/v1/assessment/list';
export const assessmentAdd = '/api/v1/assessment/add';
export const assessmentDetail = '/api/v1/assessment/detail/';

export const runAssessmentList = '/api/v1/client/run/list';
export const runAssessmentAdd = '/api/v1/client/run/add';

export const takeAssessmentRunList = '/api/v1/take/assessment/run/list';
export const takeAssessmentRunDetail = '/api/v1/take/assessment/run/detail/';
export const takeAssessmentRunAnswerAdd = '/api/v1/take/assessment/run/answer/add';
export const takeAssessmentRunUpdate = '/api/v1/take/assessment/run/update';