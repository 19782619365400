import { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Tab, Tabs } from "react-bootstrap";

import { getRandomChars } from "../../../utils/utils";
import { getApi, postApi } from "../../../shared/services/apiHelper";
import { assessmentAdd, assessmentDetail } from "../../../shared/settings/apiConfig";
import { TextInput } from "../../../shared/form/FormInput";

import './AssessmentAdd.scss';

const questionTypes = [
    {
        id: "0",
        questionType: "Single Input Question",
    },
    {
        id: "1",
        questionType: "Multiple Checkbox",
    },
    {
        id: "2",
        questionType: "Checkbox",
    },
    {
        id: "3",
        questionType: "Radiobutton",
    },
    // {
    //     id: "4",
    //     questionType: "Image",
    // },
    // {
    //     id: "6",
    //     questionType: "Video",
    // },
    // {
    //     id: "7",
    //     questionType: "Rating",
    // }
]

const operators = ["==", ">", "<", ">=", "<="];

export const AssessmentAddFn = (props) => {
    const defaultSection = {
        sectionname: "Untitled Section Name",
        sectionsummary: "",
        questions: []
    };

    const showOptionsForQuestionTypes = ["Multiple Checkbox", "Checkbox", "Radiobutton"];

    const showMediaQuestionTypes = ["Image", "Video"];

    const [assessmentName, setAssessmentName] = useState('');
    const [assessmentSubtitle, setAssessmentSubtitle] = useState('');

    const [questionId, setQuestionId] = useState('');

    const [sections, setSections] = useState([JSON.parse(JSON.stringify(defaultSection))]);

    const [sectionModal, setSectionModal] = useState({ show: false, data: {} });
    const [questionModal, setQuestionModal] = useState({ show: false, data: {} });
    const [settingsModal, setSettingsModal] = useState({ show: false, data: {} });
    const [dependencyHolder, setDependencyHolder] = useState({});

    useEffect(() => {
        if (props.location.pathname.includes('edit')) {
            getApi(assessmentDetail + props.match.params.assessmentid).then((response) => {
                console.log(response.data.data);
                const data = response.data.data;
                setAssessmentName(data.assessmentname);
                setAssessmentSubtitle(data.subtitle);
                setSections(data.sections);
            });
        }
    }, [])

    const saveAssessmentDetail = (type, event) => {
        if (type == 'assessmentname') {
            setAssessmentName(event.target.value);
        } else if (type == 'assessmentsubtitle') {
            setAssessmentSubtitle(event.target.value);
        }
    }

    const addSection = () => {
        setSections(prevState => { return [...prevState, ...JSON.parse(JSON.stringify([{ ...defaultSection }]))]; });
    }

    const updateSection = (sectionIndex) => {
        let data = { sectionId: sectionIndex, sectionname: sections[sectionIndex].sectionname, sectionsummary: sections[sectionIndex].sectionsummary };
        setSectionModal(prevState => { return { ...prevState, show: true, data }; });
    };

    const onChangeSectionData = (type, event) => {
        let data = { ...sectionModal.data, [type]: event.target.value };
        setSectionModal(prevState => { return { ...prevState, data }; });
    }

    const saveSection = () => {
        let sectionIndex = sectionModal.data.sectionId;
        sections[sectionIndex].sectionname = sectionModal.data.sectionname;
        sections[sectionIndex].sectionsummary = sectionModal.data.sectionsummary;
        setSections(sections);
        closeSectionModal();
    }

    const closeSectionModal = () => {
        setSectionModal(prevState => { return { ...prevState, show: false, data: {} }; });
    }

    const dragQuestions = (event) => {
        setQuestionId(event.target.id);
    }

    const dropQuestions = (event, scindex) => {
        event.preventDefault();
        if (scindex >= 0) {
            let data = { sectionId: scindex };
            setQuestionModal(prevState => { return { ...prevState, show: true, data }; });
        }
    }

    const allowDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const getDefaultQuestionData = (questionName, hint, questionType, questionTypeName, questionMandatory, questionOrder) => {
        let optionSetData = getDefaultOptionSetData(true);
        return {
            questionname: questionName,
            hint: hint,
            questiontype: questionType,
            questiontypename: questionTypeName,
            mandatory: questionMandatory,
            questionorder: questionOrder,
            questionuniqueid: `q-${Date.now()}`,
            optionset: optionSetData ? [optionSetData] : []
        };
    };

    const getDefaultOptionSetData = (isDefaultSet=false, optionSetLength=null) => {
        if (showOptionsForQuestionTypes.includes(questionTypes[questionId]["questionType"])) {
            return { isdefaultset: isDefaultSet, options: [], temp_option_name: "", temp_option_score: "", setnumber: optionSetLength || 1 };
        } else {
            return null;
        }
    };

    const getDefaultOptionData = (optionName, score) => {
        return { optionname: optionName, score: score };
    }

    const addQuestion = () => {
        let sectionId = questionModal.data.sectionId;

        let questionList = sections[sectionId].questions;
        questionList.push(getDefaultQuestionData(questionModal.data.questionname, questionModal.data.hint || "", questionTypes[questionId]['id'], questionTypes[questionId]['questionType'], false, questionList.length + 1));

        sections[sectionId].questions = questionList;
        setSections(sections);

        questionModalClose();
    };

    const onChangeQuestionData = (type, event) => {
        let data = { ...questionModal.data, [type]: event.target.value };
        setQuestionModal(prevState => { return { ...prevState, data } });
    };

    const questionModalClose = () => {
        setQuestionModal(prevState => { return { ...prevState, show: false, data: {} }; });
    };

    const openModalToeditQuestion = (scindex, qindex) => {
        let questionData = sections[scindex].questions[qindex];
        let data = { sectionId: scindex, questionId: qindex, ...questionData, update: true };
        setQuestionModal(prevState => { return { ...prevState, show: true, data }; });
    }

    const updateQuestion = () => {
        let sectionId = questionModal.data.sectionId;
        let questionId = questionModal.data.questionId;

        // Deleting unwanted keys
        delete questionModal.data.sectionId;
        delete questionModal.data.questionId;
        delete questionModal.data.update;

        sections[sectionId].questions[questionId] = questionModal.data;
        setSections(sections);
        closeQuestionModal();
    };

    const closeQuestionModal = () => {
        setQuestionModal(prevState => { return { ...prevState, show: false, data: {} }; });
    }

    const deleteQuestion = (scindex, qindex) => {
        let sectionData = [...sections];

        let questionList = sectionData[scindex].questions;
        questionList.splice(qindex, 1);

        sectionData[scindex].questions = questionList;
        setSections(sectionData);
    }

    const saveAssessment = () => {
        let assessmentData = {
            assessmentname: assessmentName,
            subtitle: assessmentSubtitle,
            scoreparameter: 1,
            version: 1,
            sections: sections
        };

        postApi(assessmentAdd, {data: assessmentData}).then(response => {
            props.history.goBack();
        }).catch((error) => {
            console.log(error);
        });
    };

    const openSettingsModal = (sIndex, qIndex) => {
        let questionData = JSON.parse(JSON.stringify(sections[sIndex].questions[qIndex]));
        let data = { sectionId: sIndex, questionId: qIndex, ...questionData };
        setSettingsModal(prevState => { return { ...prevState, show: true, data }; });
    };

    const closeSettingsModal = () => {
        setSettingsModal(prevState => { return { ...prevState, show: false, data: {} }; });
    };

    const addOptionSet = () => {
        let data = settingsModal.data;
        let optionData = getDefaultOptionSetData(false, data.optionset.length + 1);
        data.optionset = [...data.optionset, ...(optionData ? [optionData] : [])];
        setSettingsModal(prevState => { return { ...prevState, data }; });
    }

    const onChangeOptionData = (type, optionSetIndex, event) => {
        let eventValue = event.target.value;
        let data = settingsModal.data;
        data.optionset[optionSetIndex][type] = eventValue;
        setSectionModal(prevState => { return { ...prevState, data } });
    };

    const addOption = (optionSetIndex) => {
        let data = settingsModal.data;
        let optionSetData = data.optionset[optionSetIndex];
        optionSetData.options.push(getDefaultOptionData(optionSetData.temp_option_name, optionSetData.temp_option_score));

        optionSetData.temp_option_name = "";
        optionSetData.temp_option_score = "";

        setSettingsModal(prevState => { return { ...prevState, data }; });
    };

    const clearOption = (optionSetIndex) => {
        let data = settingsModal.data;
        let optionSetData = data.optionset[optionSetIndex];

        optionSetData.temp_option_name = "";
        optionSetData.temp_option_score = "";

        setSettingsModal(prevState => { return { ...prevState, data }; });
    };

    const onChangeAnswerSet = (opSetIndex) => {
        let data = settingsModal.data;
        data.optionset.forEach((element, index) => {
            element['isdefaultset'] = index == opSetIndex ? true : false;
        });
        setSettingsModal(prevState => { return { ...prevState, data }; });
    };

    const saveSettings = () => {
        let data = settingsModal.data;

        const sectionData = [...sections];
        let questionData = sectionData[data['sectionId']].questions[data['questionId']];
        questionData.optionset = data.optionset;
        questionData.linkquestion = data.linkquestion;

        setSections(sectionData);

        closeSettingsModal();
    };

    const onSettingTabChange = (key) => {
        if ( key == 'link-question' ) {
            if ( typeof settingsModal?.data?.sectionId !== 'undefined' && sections[settingsModal.data.sectionId].questions.length > 0 ) {
                let questions = JSON.parse(JSON.stringify(sections[settingsModal.data.sectionId].questions));
                questions.splice(settingsModal.data.questionId, 1);
                
                let linkQuestion = {};
                if ( settingsModal.data.linkquestion ) {
                    linkQuestion = settingsModal.data.linkquestion;
                } else {
                    linkQuestion['questions'] = questions.map(elem => { return { questionname: elem.questionname, questiontype: elem.questiontype, questionuniqueid: elem.questionuniqueid } });
                }

                setSettingsModal(prevState => { 
                    prevState.data.linkquestion = linkQuestion;
                    return { ...prevState };
                });
            }
        }
    };

    const onChangeLinkQuestion = (type, value, index=null) => {
        let linkQuestion = settingsModal.data.linkquestion ? settingsModal.data.linkquestion : {};

        if ( type == 'selectallquestions' ) {
            linkQuestion[type] = value;
            linkQuestion.questions.forEach(element => {
                element['checked'] = value;
            });
        } else if ( type == 'question' ) {
            linkQuestion.questions[index]['checked'] = value;
            linkQuestion['selectallquestions'] =  linkQuestion.questions.filter(elem => { return elem.checked; }).length === linkQuestion.questions.length ? true : false;
        } else {
            linkQuestion[type] = value;
        }

        setSettingsModal(prevState => { 
            prevState.data.linkquestion = linkQuestion;
            return { ...prevState };
        });
    };

    return (
        <div className="assessment-add-container" style={{height: (window.screen.height - 190) + "px"}}>
            <div className="assessment-container">
                {/** Question Types */}
                <div className="question-side-bar">
                    <div className="question-head fw-bold">Question Types</div>
                    {
                        questionTypes.map((data, index) => (
                            <div key={"question-type-" + index} className="question-design cursor" id={data.id} draggable="true" onDragStart={(e) => dragQuestions(e)}>
                                <div className="question-type">{data.questionType}</div>
                                <img className="question-drag-icon" src={`${process.env.PUBLIC_URL}/static/images/arrow.png`} alt="arrow" />
                            </div>
                        ))
                    }
                </div>

                {/** Section Container */}
                <div className="section-container">

                    {/** Top Section */}
                    <div className="top-section">
                        <div className="w-90">
                            <div className="top-section-input-container">
                                <TextInput name={'assessmentname'} placeholder={'Assessment Name'} value={assessmentName}
                                    onChange={(event) => saveAssessmentDetail('assessmentname', event)} />
                                <TextInput name={'assessmentsubtitle'} placeholder={'Assessment Subtitle'} value={assessmentSubtitle}
                                    onChange={(event) => saveAssessmentDetail('assessmentsubtitle', event)} />
                            </div>
                        </div>
                        <div className="top-section-button-container">
                            <Button type="button" className="btn btn-primary" onClick={addSection}>
                                <img width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                Section
                            </Button>
                        </div>
                    </div>

                    {/** Middle Section */}
                    <div className="middle-section">
                        {sections.map(((section, scindex) => (
                            <div style={{ minHeight: section.questions.length ? 'unset' : '150px' }} className="section" id={scindex} key={"section-" + scindex} onDragOver={(e) => allowDrop(e)} onDrop={(e) => { dropQuestions(e, scindex) }}>
                                <div className="section-title-container" onClick={() => { updateSection(scindex, section) }}>
                                    <div className="section-title">
                                        Section {scindex + 1}: {section.sectionname}
                                        <img style={{ width: "22px", padding: "5px" }} src={`${process.env.PUBLIC_URL}/static/images/edit.png`} alt="edit" />
                                    </div>
                                    <div>{section.sectionsummary}</div>
                                </div>
                                {
                                    section.questions.map((qitem, qindex) => (
                                        <div key={"question-" + qindex} className="question-container">
                                            <Form.Group className="question-mandatory">
                                                <Form.Check custom type="checkbox" name="question" value={qitem.mandatory} />
                                            </Form.Group>
                                            <div className="question-title">
                                                <div style={{ fontSize: '10px' }}>{qitem["questiontypename"]}</div>
                                                <div style={{ fontSize: '14px' }}>
                                                    {showMediaQuestionTypes.includes(qitem.questionid) ?
                                                        <img src={qitem.image} value={qitem.file_name} id="img" style={{ height: "40px", width: "40px" }} alt="upload-image" /> :
                                                        qitem["questionname"]}
                                                </div>
                                                <div style={{ fontSize: '10px' }}>{qitem["hint"]}</div>
                                            </div>
                                            <div className="question-actions">
                                                <img src={`${process.env.PUBLIC_URL}/static/images/settings.png`} alt="settings" onClick={() => { openSettingsModal(scindex, qindex) }} />
                                                <img src={`${process.env.PUBLIC_URL}/static/images/edit.png`} alt="edit" onClick={() => { openModalToeditQuestion(scindex, qindex) }} />
                                                <img src={`${process.env.PUBLIC_URL}/static/images/delete.png`} alt="delete" onClick={() => { deleteQuestion(scindex, qindex) }} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )))}
                    </div>
                    <div className="bottom-section">
                        <button type="button" className="btn btn-outline-primary" onClick={() => { props.history.goBack(); }}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={saveAssessment}>Save</button>
                    </div>
                </div>
            </div>


            {/** Section Modal */}
            <Modal centered show={sectionModal.show} id="section-modal" size="md" animation={true} keyboard={false}>
                <Modal.Header className="modal-header">
                    <span className="fw-bold">Section</span>
                    <div className="close-icon" onClick={closeSectionModal}>X</div>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row-handle d-lg-flex">
                            <div className="col-handle-2">
                                <div className="float-control lg">
                                    <Form.Control size="lg" type="text" id="sectionname" name="section_name" placeholder=" " value={sectionModal.data.sectionname} onInput={(e) => { onChangeSectionData('sectionname', e) }} />
                                    <Form.Label htmlFor="sectionname" className="required">Section Name</Form.Label>
                                </div>
                            </div>
                            <div className="col-handle-2">
                                <div className="float-control lg">
                                    <Form.Control size="lg" type="text" id="sectionsummary" name="section_summary" placeholder=" " value={sectionModal.data.sectionsummary} onInput={(e) => { onChangeSectionData('sectionsummary', e) }} />
                                    <Form.Label htmlFor="sectionsummary" className="required">Section Summary</Form.Label>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ placeContent: "center" }}>
                    <Button className={`btn btn-primary mx-2`} style={{ width: '120px' }} onClick={saveSection}>Save</Button>
                </Modal.Footer>
            </Modal>

            {/** Question Modal */}
            <Modal centered show={questionModal.show} size="md" animation={true} onHide={() => { setQuestionModal(false) }} keyboard={false}>
                <Modal.Header className="modal-header">
                    Add New Question - Untitled 1
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        {questionId == "4" ?
                            <div className="w-90" style={{ paddingTop: "3%" }}>
                                <input type="file" className="form-control" placeholder="Image upload" id="image"></input>
                            </div> : ""}
                        <div className="w-90 mt-20">
                            <input type="text" className="form-control" placeholder="Question Name" id="name" value={questionModal.data.questionname} onChange={(e) => { onChangeQuestionData('questionname', e) }}></input>
                        </div>
                        <div className="question-input">
                            <input type="text" className="form-control" placeholder="Question Hint" id="hint" value={questionModal.data.hint} onChange={(e) => { onChangeQuestionData('hint', e) }}></input>
                        </div>
                        <div className="d-flex flex-row mt-4">
                            <Button variant="outline-dark btn-white mx-2" style={{ width: '120px' }} onClick={questionModalClose}>Cancel</Button>
                            <Button className="btn btn-primary mx-2" style={{ width: '120px' }} onClick={() => { questionModal.data.update ? updateQuestion() : addQuestion() }}>Save</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/** Settings Modal */}
            <Modal centered show={settingsModal.show} size="lg" animation={true}>
                <Modal.Header className="modal-header">
                    <div>{settingsModal.data.questionname}</div>
                    <div className="cursor-pointer close-icon" onClick={closeSettingsModal}>X</div>
                </Modal.Header>
                <Modal.Body>
                    <Tabs id="settings-tab" defaultActiveKey="answer-set" onSelect={onSettingTabChange}>
                        {/** Answer Set Tab */}
                        <Tab eventKey="answer-set" title="Answer Set">
                            <div className="settings-container p-10">
                                <div className="answer-set-add-container">
                                    <button type="button" className={`btn btn-primary`} onClick={addOptionSet}>
                                        <img className="w-15" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />Answer set
                                    </button>
                                </div>
                                <div className="answer-set-container">
                                    {
                                        settingsModal.data.optionset?.map((opset, opsetindex) => (
                                            <div className="answer-set" key={opsetindex}>
                                                <div className="answer-set-control-container">
                                                    <Form.Check custom className="display-flex h-center v-center" style={{width: "5%", gap: "5px", padding: 0}} type="radio" id={"optionset" + opsetindex} name="option" label={`Set ${opsetindex + 1}`} checked={opset.isdefaultset} onChange={(event) => { onChangeAnswerSet(opsetindex); }} />
                                                    <div className="answer-set-input-container">
                                                        <TextInput className="option-name" name={'optionname'} placeholder={'Option Name'} value={opset.temp_option_name} onChange={(event) => { onChangeOptionData('temp_option_name', opsetindex, event); }} />
                                                        <TextInput className="option-score" name={'score'} placeholder={'Score'} value={opset.temp_option_score} onChange={(event) => { onChangeOptionData('temp_option_score', opsetindex, event); }} />
                                                    </div>
                                                    <div className="answer-action-container">
                                                        <button type="button" className="btn btn-primary" onClick={() => { addOption(opsetindex); }} disabled={!opset.temp_option_name || !opset.temp_option_score}>+</button>
                                                    </div>
                                                    <div className="answer-action-container">
                                                        <button type="button" className="btn btn-danger" onClick={() => { clearOption(opsetindex); }}>x</button>
                                                    </div>
                                                </div>
                                                <div className="set-option-container">
                                                    {
                                                        opset.options.map((option, opindex) => (
                                                            <div className="set-option" key={opindex}>
                                                                <div className="set-option-name-score">
                                                                    <div>{option.optionname}</div>
                                                                    <div className="set-option-score">{option.score}</div>
                                                                </div>
                                                                {/* <div style={{ width: "20%", display: "flex", alignItems: "center" }}>
                                                                    <div className="img-center">
                                                                        <img style={{ width: "35%" }} className={`${this.optionEditIndex != null ? 'img-disabled' : ''}`} src={`${process.env.PUBLIC_URL}/static/images/edit.png`} alt="edit" onClick={() => { this.editOptionData(opsetindex, opindex) }} />
                                                                    </div>
                                                                    <div className="img-center">
                                                                        <img style={{ width: "40%" }} className={`${this.optionEditIndex != null ? 'img-disabled' : ''}`} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} alt="delete" onClick={() => { this.deleteOptionData(opsetindex, opindex) }} />
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="answer-set-action-container">
                                    <Button variant="outline-dark btn-white" onClick={closeSettingsModal}>Cancel</Button>
                                    <Button className="btn btn-primary" onClick={saveSettings}>Save</Button>
                                </div>
                            </div>
                        </Tab>

                        {/** Link Question Tab */}
                        <Tab eventKey="link-question" title="Link Question">
                            <div className="d-flex justify-content-center align-items-center link-question-container">
                                <div className="operator-container">
                                    <label>Operator</label>
                                    <Dropdown onSelect={(eventKey, event) => { onChangeLinkQuestion('operator', event.target.innerText); }}>
                                        <Dropdown.Toggle variant="default"  id="dropdown-basic">
                                            {settingsModal.data?.linkquestion?.operator ? settingsModal.data.linkquestion.operator : "Select Operator"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                operators.map((elem, index) => (
                                                    <Dropdown.Item key={"operator" + index} onClick={() => { }}>{elem}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <TextInput className="link-question-score" name={'score'} label={'Score'} placeholder={'Score'} value={settingsModal.data?.linkquestion?.score ? settingsModal.data.linkquestion.score : ""} onChange={(event) => { onChangeLinkQuestion('score', event.target.value); }} />

                                {
                                    Array.isArray(settingsModal?.data?.linkquestion?.questions) ? 
                                    <Form className="link-questions-all-container">
                                        <label>Questions</label>
                                        {
                                            settingsModal.data.linkquestion.questions.length > 0 ?
                                            <>
                                                <Form.Check className="no-select" type="checkbox" name="select-all-questions" id="select-all-questions" label="Select All Questions" value="Select All Questions" checked={settingsModal.data?.linkquestion?.selectallquestions ? true : false} onChange={(event) => { onChangeLinkQuestion('selectallquestions', event.target.checked); }} />
                                                <div className="link-questions-all">
                                                    {
                                                        settingsModal.data.linkquestion.questions.map((question, index) => (
                                                            <Form.Check key={"all-questions" + index} className="no-select" type="checkbox" name={"all-questions" + index} id={"all-questions" + index} label={question.questionname} value={question.questionname} checked={question.checked ? true : false} onChange={(event) => { onChangeLinkQuestion('question', event.target.checked, index); }} />
                                                        ))
                                                    }
                                                </div>
                                            </> : 
                                            <div>No questions available to configure dependency.</div>
                                        }
                                    </Form> : null
                                }

                                <div className="link-question-action-container">
                                    <Button variant="outline-dark btn-white" onClick={closeSettingsModal}>Cancel</Button>
                                    <Button className="btn btn-primary" onClick={saveSettings}>Save</Button>
                                </div>
                                
                            </div>
                        </Tab>

                        {/** Proxy Validator Tab */}
                        <Tab eventKey="proxy-validator" title="Proxy Validator">
                            Proxy Validator Tab In Progress
                        </Tab>

                        {/** Result Tab */}
                        <Tab eventKey="result" title="Result">
                            Result Tab In Progress
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>

        </div>
    )
}