import React, { Component, createRef, useState } from "react";
import { Button, Row } from "react-bootstrap";
import * as moment from 'moment-timezone';

import { Datatable } from "../../../shared/datatable/Datatable";
import { assessmentList } from "../../../shared/settings/apiConfig";
import { TextInput } from "../../../shared/form/FormInput";

import './AssessmentList.scss';
import { Validators } from "../../../shared/utils/Validators";

const assessmentFilters = {
    'assessmentName': { field: 'assessmentname', value: '', op: 'LIKE', 'join': 'AND' },
    'subtitle': { field: 'subtitle', value: '', op: 'LIKE', 'join': 'AND' },
};

const AssessmentSearch = (props) => {

    const [assessmentSearchForm, setAssessmentSearchForm] = useState({
        'assessmentName': { value: '', valid: true, error: '', validators: [] },
        'subtitle': { value: '', valid: true, error: '', validators: [] },
    });

    const onSearch = () => {
        if (props.onSearch) {
            let filters = [];
            const allFilters = {...assessmentFilters};
            const controlKeys = Object.keys(assessmentSearchForm);
            controlKeys.forEach(control => {
                if (assessmentSearchForm[control].value) {
                    filters.push({...allFilters[control], value: assessmentSearchForm[control].value});
                }
            });

            if (filters.length > 0) {
                props.onSearch(filters);
            }
        }
    };

    const onClear = () => {

        setAssessmentSearchForm(prevState => {
            const formData = {...prevState};
            const keys = Object.keys(formData);
            keys.forEach(key => {
                formData[key].value = "";
            });

            return formData;
        });

        if (props.onClear) {
            props.onClear();
        }
    };

    const setValue = (controlName, event) => {
        setAssessmentSearchForm(prevState => {
            return {
                ...prevState,
                [controlName]: Validators.validateFormControl(assessmentSearchForm[controlName], event.target.value)
            }
        });
    }

    return (
        <div className="d-flex search-container" style={{}}>
            <Row>
                <TextInput name={'assessmentName'} label={'Assessment Name'} placeholder={'Assessment Name'} 
                           value={assessmentSearchForm.assessmentName.value} error={assessmentSearchForm.assessmentName.error} onChange={(event) => setValue('assessmentName', event)} />
                <TextInput name={'subtitle'} label={'Subtitle'} placeholder={'Subtitle'} 
                           value={assessmentSearchForm.subtitle.value} onChange={(event) => setValue('subtitle', event)} />
            </Row>
            <div className="d-flex" style={{gap: '10px', justifyContent: 'flex-end'}}>
                <Button variant="outline-primary" onClick={onClear}>Clear All</Button>
                <Button variant="primary" onClick={onSearch}>Search</Button>
            </div>
        </div>
    );
};

export class AssessmentList extends Component {
    
    config;
    dataTable;

    constructor(props) {
        super(props);

        this.dataTable = createRef();

        this.config = {
            name: 'assessment',
            url: assessmentList,
            columns: [
                {
                    name: '', property: '', columnType: 'actions', width: '150px',
                    actions: {
                        header: <Button className="save-button" onClick={this.nagivateToAssessmentCreate}>+ Create</Button>,
                        columns: [
                            { type: 'edit', action: this.editAction }
                        ]
                    }
                },
                { name: 'Assessment Name', property: 'assessmentname', sort: true, width: '250px' },
                { name: 'Subtitle', property: 'subtitle', sort: true, width: '300px' },
                { name: 'Description', property: 'description', sort: true, showTooltip: true, width: '300px' },
                { name: 'Created Date', property: 'createddate', sort: true, width: '150px', customColumn: this.customDate },
            ],
            field: ['id', 'assessmentname', 'subtitle', 'description', 'createddate', 'isdeleted'],
            filter: [{ field: 'isdeleted', value: 0, op: '=', 'join': 'AND' }],
            sort: [{ "field": "assessmentname", "direction": "asc"}],
            pagination: true,
        };
    }

    editAction = (id) => {
        this.props.history.push(`/assessments/edit/${id}`);
    }

    nagivateToAssessmentCreate = () => {
        this.props.history.push('/assessments/create');
    }

    customDate = (dataValue) => {
        if (dataValue) {
            let date = moment.utc(dataValue).local();
            return `${date.format('MM/DD/YYYY hh:mm A')} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
        } else
            return '- - -';
    };

    onSearchCallback = (filters) => {
        this.dataTable.current.searchData(filters);
    };

    onClearCallback = () => {
        this.dataTable.current.searchData([]);
    };
    
    render() {
        return (
            <div className="assessmet-list">
                <AssessmentSearch onSearch={(filters) => { this.onSearchCallback(filters); } } onClear={this.onClearCallback} />
                <div style={{padding: '15px'}}>
                    <Datatable ref={this.dataTable} config={this.config} />
                </div>
            </div>
        )
    }
}