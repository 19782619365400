import { Col, Form } from "react-bootstrap";

export const TextInput = (props) => {

    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <Form.Control size="md" type={props.type ? props.type : 'text'} name={props.name} placeholder={props.placeholder} value={props.value}
                onChange={(event) => { props.onChange && props.onChange(event); }} autoComplete="off" />
            <div className="text-danger" style={{minHeight: '18px', fontSize: '12px', marginTop: '4px'}}>{props.error ? props.error : ''}</div>
        </Col>
    );
};

export const SelectInput = (props) => {
    return (
        <Col className="form-control-custom">
            <Form.Label sm="sm" htmlFor={props.name}>{props.label}</Form.Label>
            <Form.Select value={props.value} defaultValue={props.value} onChange={(event) => { props.onChange && props.onChange(event); }}>
                <option value={""} disabled>Please select a option</option>
                {
                    Array.isArray(props.options) && props.options.length > 0 ?
                    props.options.map((elem) => <option value={elem.value}>{elem.option}</option>) : ''
                }
            </Form.Select>
            <div className="text-danger" style={{minHeight: '18px', fontSize: '12px', marginTop: '4px'}}>{props.error ? props.error : ''}</div>
        </Col>
    );
};