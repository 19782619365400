import React, { Component } from "react";

import { Link } from 'react-router-dom';
import './Sidebar.scss';

export default class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.menus = [
            { name: 'Home', redirectTo: '/home' },
            
            {
                name: 'Locations',
                redirectTo: '/locations',
                openSubMenu: false,
                submenu: [
                    { name: 'Department', redirectTo: '/departments' },
                    { name: 'Assessor & Assessee', redirectTo: '/clients' },
                    
                ],
            },
            { name: 'Assessments', redirectTo: '/assessments' },
            { name: 'Run Assessment', redirectTo: '/run-assessments' },
            { name: 'Take Assessment', redirectTo: '/take-assessments/123456' },
        ];

        // Checking where to open the sub menu or not for all menus
        this.menus.forEach(element => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                if (this.props.location.pathname.includes(element.redirectTo)) {
                    element.openSubMenu = true;
                } else {
                    element.openSubMenu = element.submenu.filter(elem => { return this.props.location.pathname.includes(elem.redirectTo); }).length > 0 ? true : false
                }
            }
        });

        this.state = {
            menus: this.menus
        }
    }

    handleOpenSideMenu = (menuIndex) => {
        const menus = [...this.state.menus];
        menus.forEach((element, index) => {
            if (Array.isArray(element.submenu) && element.submenu.length > 0) {
                element.openSubMenu = menuIndex == index ? true : false;
            }
        });

        this.setState({ menus });
    };

    render() {
        return (
            <div className="d-flex flex-column sidebar-box">
                <ul className="side-menu-container">
                    {
                        this.state.menus.map((menu, index) =>
                            <li key={`menu_${index}`} className={`${this.props.location.pathname === menu.redirectTo ? 'active-menu' : ''}`} onClick={() => this.handleOpenSideMenu(index)}>
                                <Link to={menu.redirectTo} >
                                    <div>{menu.name}</div>
                                    {Array.isArray(menu.submenu) && menu.submenu.length > 0 ? <i className={`fa fa-chevron-${menu.openSubMenu ? 'down' : 'right'}`}></i> : ''}
                                </Link>
                                {
                                    menu.openSubMenu && menu.submenu?.map((subMenu, subMenuIndex) =>
                                        <div key={`submenu_${index}_${subMenuIndex}`} className={`sub-menu ${this.props.location.pathname === subMenu.redirectTo ? 'active-menu' : ''}`}>
                                            <Link to={subMenu.redirectTo}>{subMenu.name}</Link>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }
}
