import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Applayout from '../component/layouts/Applayout';
import Signin from '../component/no-auth/signin/SignIn';
import NotFound from '../component/no-auth/NotFound';

import { isAuthenticated, isAuthorized } from '../shared/settings/auth';
export class FeaturesRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            load: false
        }
    }

    componentDidMount() {
        isAuthenticated().then(() => {
            this.setState({
                load: true
            })
        })
    }

    render() {
        return (
            this.state.load ? 
                <Switch>
                    <Route exact path="/not-found" component={NotFound} />
                    <Route exact path="/">
                        {
                            isAuthorized() ? <Redirect to="/home" /> : <Signin {...this.props} />
                        }
                    </Route>
                    <Route exact path="/forgot-password">
                        {
                            isAuthorized() ? <Redirect to="/home" /> : <Signin {...this.props} />
                        }
                    </Route>
                    <Route exact path="/set-password">
                        {
                            isAuthorized() ? <Redirect to="/home" /> : <Signin {...this.props} />
                        }
                    </Route>
                    <Route>
                        {
                            isAuthorized() ? <Applayout {...this.props} /> : <Redirect to="/" />
                        }
                    </Route>
                    <Redirect to="/not-found" />
                </Switch> : <></>
        );
    }
}