import moment from 'moment';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { Button, Form, Spinner } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import { SelectDropdown } from '../../../shared/dropdown/Dropdown';
import { Validators } from '../../../shared/utils/Validators';
import { postApi } from '../../../shared/services/apiHelper';
import { assessmentList, runAssessmentAdd, userList } from '../../../shared/settings/apiConfig';

import './RunAssessmentsAdd.scss';

const runType = ["Run Now", "Schedule Later"];

export const RunAssessmentsAdd = (props) => {

    const loggedInUserData = useSelector((state) => { return state.user.id });

    const [runAssessmentFormData, setRunAssessmentFormData] = useState({
        clientId: { value: '', error: '', valid: false, validators: [Validators.required()] },
        assessmentName: { value: [], error: '', valid: false, validators: [Validators.required()] },
        runType: { value: runType[0], error: '', valid: true, validators: [Validators.required()] },
        expiryDate: { value: '', error: '', valid: false, validators: [Validators.required()] }
    });
    const [formValid, setFormValid] = useState(false);

    const [isloading, setIsLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    const [clientData, setClientData] = useState([]);
    const [assessmentData, setAssessmentData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getClientDetails(), getAssessmentList()]).then(responses => {
            setClientData(responses[0]);
            setAssessmentData(responses[1]);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        setFormValid(Validators.validateForm(runAssessmentFormData));
    }, [runAssessmentFormData]);

    const getClientDetails = () => {
        let payload = {
            field: ["id", "firstname", "lastname"],
            filter: [
                {
                    field: "id",
                    value: loggedInUserData,
                    op: "!=",
                    join: "AND"
                }
            ]
        };
        return new Promise((resolve, reject) => {
            postApi(userList, payload).then(response => {
                resolve(response.data.data);
            }).catch((error) => {
                reject(error);
            });
        })
    }

    const getAssessmentList = () => {
        let payload = {
            field: ["id", "assessmentname"],
            filter: [
                {
                    field: 'isdeleted',
                    value: 0,
                    op: '=',
                    'join': 'AND'
                }
            ]
        }
        return new Promise((resolve, reject) => {
            postApi(assessmentList, payload).then(response => {
                resolve(response.data.data);
            }).catch(error => {
                reject(error);
            })
        })
    }

    const onInputChange = (type, event, value) => {
        let formControl = {...runAssessmentFormData};
        let inputValue = '';

        if ( type == "clientId" ) {
            inputValue = event.target.value;
        } else if ( type == 'assessmentName' ) {
            inputValue = value;
        } else if ( type == "runType" ) {
            inputValue = event.target.value;

            if ( inputValue === runType[0] ) {
                delete formControl['scheduleDate']
            } else {
                formControl['scheduleDate'] = { value: '', error: '', valid: false, validators: [Validators.required()] }
            }

        } else if ( type == "scheduleDate" ) {
            inputValue = event;
        } else if ( type == "expiryDate" ) {
            inputValue = event;
        }

        setRunAssessmentFormData(() => {
            return {
                ...formControl,
                [type]: Validators.validateFormControl(formControl[type], inputValue)
            }
        });
    };

    const clearAssessmentSelected = () => {
        onInputChange('assessmentName', null, []);
    }

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const filterPassedEndTime = (time) => {
        const currentDate = runAssessmentFormData.scheduleDate?.value ? runAssessmentFormData.scheduleDate.value : new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const saveRunAssessment = () => {
        setFormLoading(true);
        let data = {
            "clientid": runAssessmentFormData.clientId.value,
            "assessment": runAssessmentFormData.assessmentName.value,
            "runtype": runAssessmentFormData.runType.value,
            "expirydate": moment(runAssessmentFormData.expiryDate.value).utc().format("YYYY-MM-DD HH:mm:ss")
        };

        if ( data['runtype'] === runType[0] ) {
            data['rundate'] = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        } else {
            data['scheduleddate'] = moment.utc(runAssessmentFormData.scheduleDate.value).format("YYYY-MM-DD HH:mm:ss")
        }

        postApi(runAssessmentAdd, {data}).then(response => {
            setFormLoading(false);
            props.history.goBack();
        }).catch((error) => {
            console.log(error);
        });
    }

    const cancelRunAssessment = () => {
        props.history.goBack();
    }

    return (
        <div className='h-100 d-flex align-items-center justify-content-center overflow-auto'>
            {
                isloading ?
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='run-loader-img' src={`${process.env.PUBLIC_URL}/static/images/loader.svg`} alt="loader-icon" />
                    </div> :
                    <div className='run-assessment-container mt-10'>

                        {/** Client Dropdown */}
                        <div className='w-100'>
                            <Form.Label className='run-assesse-label'>Select Client</Form.Label>
                            <Form.Control as="select" id="clientId" name="clientId" value={runAssessmentFormData?.clientId?.value} error={runAssessmentFormData.clientId.error} onChange={(event) => onInputChange('clientId', event)} disabled={clientData.length == 0 ? true : false}>
                                <option disabled={true} value={""}>Select Client</option>
                                {
                                    clientData?.map((client, key) => <option key={key} id={client.id} value={client.id}>{client.firstname + " " + client.lastname}</option>)
                                }
                            </Form.Control>
                            {
                                clientData.length == 0 ? <span className='text-info'>No clients available</span> : null
                            }
                            <span className='text-danger'>
                                {
                                    runAssessmentFormData.clientId.error && runAssessmentFormData.clientId.error.length ? <small className="error">{runAssessmentFormData.clientId.error}</small> : null
                                }
                            </span>
                        </div>

                        {/** Assessment Dropdown */}
                        <div className='w-100'>
                            <Form.Label className='run-assesse-label'>Select Assessment</Form.Label>
                            <SelectDropdown multiple placeholder="Select Assessment" id="assessment" value={runAssessmentFormData.assessmentName.value} error={runAssessmentFormData.assessmentName.error} onChange={(value) => { onInputChange('assessmentName', null, value); }} onClear={clearAssessmentSelected}
                                data={assessmentData} />
                            {
                                assessmentData.length == 0 ? <span className='text-info'>No assessment(s) available</span> : null
                            }
                            <span className='text-danger'>
                                {
                                    runAssessmentFormData.assessmentName.error && runAssessmentFormData.assessmentName.error.length ? <small className="error">{runAssessmentFormData.assessmentName.error}</small> : null
                                }
                            </span>
                        </div>

                        {/** Run Type */}
                        <div className='w-100'>
                            <Form.Label className='run-assesse-label'>Run Type</Form.Label>
                            <div className='run-type-controls'>
                                {
                                    runType.map((elem, index) => (
                                        <Form.Check key={"runtype-" + index} type="radio" name="runtype" id={elem} label={elem} value={elem} defaultChecked={runAssessmentFormData.runType.value == elem} error={runAssessmentFormData.runType.error} onChange={(event) => { onInputChange('runType', event, elem) }} />
                                    ))
                                }
                            </div>
                            <span className='text-danger'>
                                {
                                    runAssessmentFormData.runType.error && runAssessmentFormData.runType.error.length ? <small className="error">{runAssessmentFormData.runType.error}</small> : null
                                }
                            </span>
                        </div>

                        {
                            runAssessmentFormData?.runType?.value == runType[0] ?
                            <div className='w-100'>
                                <Form.Label className='run-assesse-label'>Run Date & Time</Form.Label>
                                <div>Start's Now</div>
                            </div> : 
                            <div className='datepicker-control w-100'>
                                <Form.Label className='run-assesse-label'>Scheduled Start Date & Time</Form.Label>
                                <DatePicker
                                    id='scheduleDate'
                                    name='scheduleDate'
                                    placeholderText="Select Schedule Date"
                                    icon="fa fa-calendar"
                                    showIcon
                                    showTimeSelect
                                    toggleCalendarOnIconClick
                                    dropdownMode="select"
                                    timeIntervals={30}
                                    dateFormat="MM/dd/yyyy hh:mm a"
                                    timeFormat="HH:mm:ss"
                                    selected={runAssessmentFormData.scheduleDate.value ? runAssessmentFormData.scheduleDate.value : ''}
                                    minDate={new Date()}
                                    filterTime={filterPassedTime}
                                    onChangeRaw={(event) => event.preventDefault()}
                                    onChange={(date) => onInputChange("scheduleDate", date) } />
                                <span className='text-danger'>
                                    {
                                        runAssessmentFormData.scheduleDate.error && runAssessmentFormData.scheduleDate.error.length ? <small className="error">{runAssessmentFormData.scheduleDate.error}</small> : null
                                    }
                                </span>
                            </div>
                        }

                        <div className='datepicker-control w-100'>
                            <Form.Label className='run-assesse-label'>Expiry Date & Time</Form.Label>
                            <DatePicker
                                    id='enddate'
                                    name='enddate'
                                    placeholderText="Select End Date"
                                    icon="fa fa-calendar"
                                    showIcon
                                    showTimeSelect
                                    toggleCalendarOnIconClick
                                    dropdownMode="select"
                                    timeIntervals={30}
                                    dateFormat="MM/dd/yyyy hh:mm a"
                                    timeFormat="HH:mm:ss"
                                    selected={runAssessmentFormData.expiryDate.value ? runAssessmentFormData.expiryDate.value : ''}
                                    filterTime={filterPassedEndTime}
                                    minDate={runAssessmentFormData?.scheduleDate?.value ? new Date(runAssessmentFormData.scheduleDate.value) : new Date()}
                                    onChangeRaw={(event) => event.preventDefault()}
                                    onChange={(date) => onInputChange("expiryDate", date) } />
                            <span className='text-danger'>
                                {
                                    runAssessmentFormData.expiryDate.error && runAssessmentFormData.expiryDate.error.length ? <small className="error">{runAssessmentFormData.expiryDate.error}</small> : null
                                }
                            </span>
                        </div>

                        <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
                            <Button onClick={cancelRunAssessment}>Cancel</Button>
                            <Button className='d-flex' onClick={saveRunAssessment} disabled={!formValid}>Save {formLoading ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</Button>
                        </div>
                    </div>
            }
        </div>
    )
}