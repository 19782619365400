import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { ClientAddEdit } from "../component/features/clients/ClientAddEdit";
import { ClientList } from "../component/features/clients/ClientList";

export class ClientRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/clients"} component={ClientList} />
                <Route exact path={[`/clients/add`, `/clients/edit/:id`]} component={ClientAddEdit} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};
