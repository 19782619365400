import { jwtDecode } from "jwt-decode";

export const getValue = (key) => {
    const token = localStorage.getItem(key);
    if (token) { 
        return token;
    }

    return null;
}

export const setValue = (key, value) => {
    localStorage.setItem(key, value);
}

export const clearValue = (key) => {
    localStorage.removeItem(key);
};

export const decodeToken = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken ? decodedToken : null;
};

export const isTokenExpired = (key) => {
    const token = getValue(key);
    if (token) {
        const decoded = decodeToken(token);

        return new Date(decoded.exp * 1000).getTime() <= new Date().getTime();
    }

    return true;
};