import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Validators } from '../../shared/utils/Validators';
import { TextInput } from '../../shared/form/FormInput';

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {
                profileFirstName: { value: 'elango', valid: false, error: '', Validators: [Validators.required()] },
                profileLastName: { value: 'kanagaraji', valid: false, error: '', Validators: [Validators.required()] },
                profileEmail: { value: 'elangokanagraji@gmail.com', valid: false, error: '', Validators: [Validators.required(), Validators.email()] },
                profilePhone: { value: '9574102545', valid: false, error: '', Validators: [Validators.required(), Validators.maxLength(10)] },
            },

        }
    }
    render() {
        return (
            <div>
                <div className="client-box">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                     <img src={process.env.PUBLIC_URL + '/static/icons/profile/profile.png'} alt="profile" height="100px" width="100px" className="" />
                    </div>
                    <Form className="d-flex flex-column justify-content-center">
                        
                        <TextInput type={'text'} name={'profileFirstName'} label={'First Name'} placeholder={'First Name'}
                            value={this.state.profile.profileFirstName.value} error={this.state.profile.profileFirstName.error} onChange={(event) => this.inputChange(event)} />

                        <TextInput type={'text'} name={'profileLastName'} label={'Last Name'} placeholder={'Last Name'}
                            value={this.state.profile.profileLastName.value} error={this.state.profile.profileLastName.error} onChange={(event) => this.inputChange(event)} />

                        <TextInput type={'text'} name={'profileEmail'} label={'Email'} placeholder={'Email'}
                            value={this.state.profile.profileEmail.value} error={this.state.profile.profileEmail.error} onChange={(event) => this.inputChange(event)} />

                        <TextInput type={'text'} name={'profilePhone'} label={'Phone'} placeholder={'Phone'}
                            value={this.state.profile.profilePhone.value} error={this.state.profile.profilePhone.error} onChange={(event) => this.inputChange(event)} />
                        <div className="d-flex flex-row justify-content-center">
                            <Link to='/home'>
                                <Button type="button" variant="outline-primary" className="cancel-button mx-1 btn-white" style={{ width: "90px" }}>Cancel</Button>
                            </Link>
                            <Button type="button" variant="secondary" className="save-button mx-1" style={{ width: "90px" }} disabled={true}>Save </Button>
                        </div>
                    </Form>

                </div>
            </div>
        )
    }
}