export class Validators{

    static required  (msg = "Please enter value") {
        return (value) => {
            if ((Array.isArray(value) && value.length == 0) || !value) {
                return msg;
            }
            return '';
        };
    }
    static phoneNumberValid(msg = "Please enter valid Phone number"){
        return (value) => {
            if(value) return '';
            return msg
        }
    }
    static maxLength = (max, msg = `Maximum ${max} charcter`) => {
        return value => {

        if(value.length <= max) return '';

        return msg

        }
    }
    static email = ( msg = "Please enter a valid email address")=> {
        return (value) => {
            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
            if(regex.test(value)) return ''
            return msg

        }
    }
    static testMsg(msg = "message"){
        return (value) => {
            if(value){
                return msg
            }
            return ''
        }
    }

    static validateFormControl = (control, value) => {
        let error = ''
        let valid = true;

        /* checks all the validators for the control */
        const validators = control.validators;
        for (let validator of validators) {
            error = validator(value);
            if (error) {
                valid = false;
                break;
            }
        }

        return { value, valid, error, validators };
    };

    static validateAndSetAllFormControls = (formControls, data) => {
        let controlKeys = Object.keys(formControls);
        controlKeys.forEach(controlKey => {
            let control = formControls[controlKey];
            formControls[controlKey] = this.validateFormControl(control, data[controlKey]);
        });

        return formControls;
    };

    static validateForm = (allControls) => {
        let formValid = true;
        let controls = Object.keys(allControls);
        for (let control of controls) {
            if (!allControls[control].valid) {
                formValid = false;
                break;
            }
        }

        return formValid;
    };
}