import React, { useEffect, useState } from "react";
import { Tabs, Tab, Form, Button } from "react-bootstrap";
import { Stepper, Step } from 'react-form-stepper';
import { DebounceInput } from "react-debounce-input";

import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";
import { takeAssessmentRunAnswerAdd, takeAssessmentRunDetail, takeAssessmentRunList, takeAssessmentRunUpdate } from "../../../shared/settings/apiConfig";

import './TakeAssessment.scss';

const RUN_STATUS = {
    NOTSTARTED: 'Not Started',
    INPROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    EXPIRED: 'Expired'
};

const QUESTION_TYPE = {
    SINGLEINPUT: 0,
    MULTIPLECHECKBOX: 1,
    CHECKBOX: 2,
    RADIOBUTTON: 3
};

export const TakeAssessment = (props) => {

    const [assessmentPage, setAssessmentPage] = useState(null);
    const [runAssessmentList, setRunAssessmentList] = useState([]);
    const [assessmentData, setAssessmentData] = useState({});

    const [activeTab, setActiveTab] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        let payload = {
            filter: [
                {
                    field: "code",
                    value: props.match.params.code,
                    op: "=",
                    join: "AND"
                }
            ]
        };
        postApi(takeAssessmentRunList, payload).then(response => {
            const assessmentRunListData = response.data.data;
            setRunAssessmentList(assessmentRunListData);

            if ( assessmentRunListData.length > 0 ) {
                
                // Overall run is in 'Not Started' then we need to show liability page
                if ( assessmentRunListData[0]['runstatus'] == RUN_STATUS.NOTSTARTED ) {
                    setIsLoading(false);
                    setAssessmentPage(1);
                } else if ( assessmentRunListData[0]['runstatus'] == RUN_STATUS.INPROGRESS ) { // Overall run is in 'In Progress' then we need to show assessment page
                    getAssessmentDetailApi(assessmentRunListData, null);
                } else {
                    setIsLoading(false);
                    setAssessmentPage(3);
                }
            } else {
                setIsLoading(false);
                setAssessmentPage(0);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const getAssessmentDetailApi = (assessmentRunListData, clientRunAssessmentId=null, nextOrSubmitAssessment=false) => {
        let runAssessmentId, index;
        if ( clientRunAssessmentId ) {
            runAssessmentId = clientRunAssessmentId;
            index = assessmentRunListData.findIndex(elem => { return elem.clientrunasessmentid == runAssessmentId; });
        } else if ( !nextOrSubmitAssessment ) {
            index = assessmentRunListData.findIndex(elem => { return elem.assessmentrunstatus == RUN_STATUS.INPROGRESS });
            if ( index > -1 ) {
                runAssessmentId = assessmentRunListData[index]['clientrunasessmentid'];
            }
        }

        if ( runAssessmentId ) {
            setIsLoading(true);
            getApi(takeAssessmentRunDetail + runAssessmentId).then(response => {
                setIsLoading(false);
                setAssessmentPage(2);
                setActiveTab(index);
                setActiveStep(0);
                setAssessmentData(response.data.data[0]);
            }).catch(error => {
                console.log(error);
            });
        } else {
            setAssessmentPage(3);
        }
    };

    const onChangeInputAnswer = (event, question, option, qIndex, oIndex) => {
        if ( question.questiontype === QUESTION_TYPE.SINGLEINPUT ) {
            
            setAssessmentData(prevState => {
                prevState.sections[activeStep].questions[qIndex].singleinputanswer = event.target.value;
                return {...prevState};
            });
    
            runAnswerAddApi(question.id, null, true, event.target.value);

        } else if ( question.questiontype === QUESTION_TYPE.MULTIPLECHECKBOX ) {
            
            setAssessmentData(prevState => {
                let options = prevState.sections[activeStep].questions[qIndex].options;
                options.forEach((element, index) => {
                    if ( index == oIndex ) {
                        element.answered = event.target.checked;
                    }
                });
                return {...prevState};
            });

            runAnswerAddApi(question.id, option.id);

        } else if ( question.questiontype == QUESTION_TYPE.CHECKBOX || question.questiontype == QUESTION_TYPE.RADIOBUTTON ) {
            
            setAssessmentData(prevState => {
                let options = prevState.sections[activeStep].questions[qIndex].options;
                options.forEach((element, index) => {
                    if ( index == oIndex ) {
                        element.answered = event.target.checked;
                    } else {
                        element.answered = false;
                    }
                });
                return {...prevState};
            });

            runAnswerAddApi(question.id, option.id);

        }
          
    };

    const runAnswerAddApi = (questionId, optionId, singleInputAnswer=false, singleInputAnswerValue=null) => {
        let activeAssessment = runAssessmentList[activeTab];
        let payload = {
            "clientrunid": activeAssessment['clientrunid'],
            "clientrunassessmentid": activeAssessment['clientrunasessmentid'],
            "questionid": questionId
        };

        if ( singleInputAnswer ) {
            payload['singleinputanswer'] = singleInputAnswerValue;
        } else {
            payload['optionid'] = optionId;
        }

        postApi(takeAssessmentRunAnswerAdd, {data: payload}).then((response) => {
            if ( 'removequestions' in response.data.data && Array.isArray(response.data.data.removequestions) && response.data.data.removequestions.length > 0 ) {
                setAssessmentData(prevState => {
                    let data = {...prevState};
                    let questions = data.sections[activeStep].questions;
                    response.data.data['removequestions'].forEach((elem) => {
                        let index = questions.findIndex((item) => { return item.id == elem; });
                        if (index > -1) {
                            questions.splice(index, 1);
                        }
                    });
                    return data;
                });
            } else if ( 'addquestions' in response.data.data && Array.isArray(response.data.data.addquestions) && response.data.data.addquestions.length > 0 ) {
                setAssessmentData(prevState => {
                    let data = {...prevState};
                    let questions = data.sections[activeStep].questions;

                    response.data.data['addquestions'].forEach((elem) => {
                        let index = questions.findIndex((item) => { return item.id == elem.id; });
                        if (index < 0) {
                            questions.push(elem);
                        }
                    });

                    data.sections[activeStep].questions = questions;
                    return data;
                });
            }
        });
    };

    const updateRunStatus = () => {
        let payload = {
            data: { clientrunid: runAssessmentList[0]['clientrunid'] }
        };
        putApi(takeAssessmentRunUpdate, payload).then(response => {
            getAssessmentDetailApi(runAssessmentList, response.data.data.clientrunassessmentid, true);
        });
    }

    const goToPreviousSection = () => {
        setActiveStep(activeStep - 1);
    }

    const goToNextSection = () => {
        setActiveStep(activeStep + 1);
    };

    const ContentSwitcher = () => {
        if (assessmentPage == 0) {
            return (
                <div className="take-no-assessment">
                    <div style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{`No assessment(s) available.`}</div>
                </div>
            )
        } else if (assessmentPage == 1) {
            return (
                <div className="take-assessment-liability">
                    <div style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>You are about to start {runAssessmentList.length} assessment(s). Please click the 'Start Assessment' button to proceed.</div>
                    <Button onClick={updateRunStatus}>Start Assessment</Button>
                </div>
            )
        } else if (assessmentPage == 2) {
            return (
                <div className="take-assessment-container">
                    <Tabs defaultActiveKey={activeTab} id="assessment-tabs">
                        {
                            runAssessmentList.map((assessment, index) => (
                                <Tab key={"assessment-tab-" + index} eventKey={index} title={assessment.assessmentname} disabled={activeTab != index}>
                                    <Stepper activeStep={activeStep}>
                                        {
                                            assessmentData.sections.map((values, stepIndex) => (
                                                <Step key={'section-stepper-' + stepIndex} label={values.sectionname} onClick={() => { }} />
                                            ))
                                        }
                                    </Stepper>
                                </Tab>
                            ))
                        }
                    </Tabs>
                    {
                        assessmentData?.sections?.[activeStep]?.questions ?
                            <div className="question-container">
                                {
                                    assessmentData.sections[activeStep].questions.map((question, qIndex) => (
                                        <div key={"question-" + qIndex} className="question">
                                            <label>{question.questionname}</label>
                                            {
                                                question.questiontype === QUESTION_TYPE.SINGLEINPUT ?
                                                    <DebounceInput type="text" debounceTimeout={500} className="form-control" value={question.singleinputanswer ? question.singleinputanswer : ''} name={question.questiontype} onChange={(event) => { onChangeInputAnswer(event, question, null, qIndex, null); }} /> : ''
                                            }
                                            {
                                                question.questiontype === QUESTION_TYPE.MULTIPLECHECKBOX ?
                                                    <Form className="option-container">
                                                        {
                                                            question.options.map((option, oIndex) => (
                                                                <Form.Check key={"option-" + qIndex + oIndex} className="option" type="checkbox" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
                                                            ))
                                                        }
                                                    </Form> : ''
                                            }
                                            {
                                                question.questiontype === QUESTION_TYPE.CHECKBOX ?
                                                    <Form className="option-container">
                                                        {
                                                            question.options.map((option, oIndex) => (
                                                                <Form.Check key={"option-" + qIndex + oIndex} className="option" type="checkbox" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
                                                            ))
                                                        }
                                                    </Form> : ''
                                            }
                                            {
                                                question.questiontype === QUESTION_TYPE.RADIOBUTTON ?
                                                    <Form className="option-container">
                                                        {
                                                            question.options.map((option, oIndex) => (
                                                                <Form.Check key={"option-" + qIndex + oIndex} className="option" type="radio" name={"question-" + qIndex} id={option.id} label={option.optionname} value={option.optionname} defaultChecked={option.answered ? true : false} onChange={(event) => { onChangeInputAnswer(event, question, option, qIndex, oIndex); }} />
                                                            ))
                                                        }
                                                    </Form> : ''
                                            }
                                        </div>
                                    ))
                                }

                                <div className="assessment-action-container">
                                    { activeStep > 0 ? <Button onClick={goToPreviousSection}>Previous Section</Button> : null }
                                    { 
                                        activeStep == assessmentData.sections.length - 1 ? 
                                        <Button onClick={updateRunStatus}>{ activeTab == runAssessmentList.length - 1 ? 'Submit Assessment' : 'Next Assessment' }</Button> : 
                                        <Button onClick={goToNextSection}>Next Section</Button>
                                    }
                                </div>
                            </div> : null
                    }
                </div>
            )
        } else {
            return (
                <div className="take-assessment-completed">
                    <div style={{ fontSize: "1.5rem", fontWeight: 'bold' }}>{`Your assessment(s) are completed.`}</div>
                </div>
            )
        }
    };

    return (
        <div className="full-width-height display-flex h-center v-center">
            {
                !isLoading ? <ContentSwitcher /> : null
            }
        </div>

    );
};
