import { useEffect, useState } from 'react';
import './Dropdown.scss'

export const SelectDropdown = (props) => {

    const [openDropdown, setOpenDropdown] = useState(false);
    const [multipleOptions, setMultipleOptions] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const setValue = () => {
        if (Array.isArray(props.value)) {
            setMultipleOptions(props.value);
        }
    };

    const onSelectChange = (data) => {
        if (!props.multiple) {
            setOpenDropdown(!openDropdown);
        } else {
            setMultipleOptions(prevState => {
                let arr = [...prevState];
                let index = arr.indexOf(data.id);
                if (index > -1) {
                    arr.splice(index, 1);
                } else {
                    arr.push(data.id);
                }

                setSelectAll(props.data.length === arr.length ? true : false);

                if (props.onChange) {
                    props.onChange(arr);
                }
                return arr;
            });
            
        }
    };

    const clearSelection = () => {
        if (props.onClear) {
            props.onClear();
        }

        setMultipleOptions([]);
        setSelectAll(false);
        setOpenDropdown(false);
    };

    const submitSelection = () => {
        if (props.onChange) {
            props.onChange(multipleOptions);
        }

        setMultipleOptions([]);
        setOpenDropdown(false);
    };

    const selectAllOptions = () => {
        let options = [];
        if (selectAll) {
            setSelectAll(false);
            setMultipleOptions(options);
        } else {
            setSelectAll(true);
            options = props.data.map((elem, index) => { return elem.id; });
            setMultipleOptions(options);
        }

        if (props.onChange) {
            props.onChange(options);
        }
    }

    return (
        <div className='multi-dropdown-container' tabIndex="0" onBlur={() => { setOpenDropdown(false); }}>
            <div className='selected-option' onClick={() => { setOpenDropdown(!openDropdown); setValue(); }}>{Array.isArray(props.value) && (props.value).length > 0 ? JSON.stringify(props.value) : props.placeholder}</div>
            {
                openDropdown ?
                    <div className='dropdown-container' style={{ width: '750px' }}>
                        <div className='d-flex dropdown-option'  onClick={() => { selectAllOptions() }}>
                            <input type="checkbox" id="selectall" name="selectall" value="selectall" checked={selectAll}></input>
                            <div>{'Select All'}</div>
                        </div>
                        {
                            Array.isArray(props.data) && props.data.map((elem, index) => {
                                return (
                                    <div className='dropdown-option' key={index} onClick={() => { onSelectChange(elem) }}>
                                        <input type="checkbox" id="singlecheckbox" name='dropdown' checked={multipleOptions.includes(elem.id)}></input>
                                        <div>{elem.assessmentname}</div>
                                    </div>
                                )
                            })
                        }
                        {
                            props.multiple ? 
                            <div className='dropdown-footer'>
                                <div className="btn-white w-10" style={{ border: "1px solid" }} onClick={clearSelection}>Clear</div>
                                <div className="btn-secondary drop-btn w-10 ml-2" onClick={submitSelection} disabled={ multipleOptions.length == 0 }>Apply</div>                                
                            </div> : null
                        }
                    </div> : null
            }
        </div>
    );
};