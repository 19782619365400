import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { DepartmentList } from "../component/features/department/DepartmentList";
import { DeparmentAddEdit } from "../component/features/department/DepartmentAddEdit";

export class DepartmentRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/departments"} component={DepartmentList} />
                <Route exact path={"/departments/create"} component={DeparmentAddEdit} />
                <Route exact path={"/departments/edit/:departmentid"} component={DeparmentAddEdit} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};