import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { TakeAssessment } from "../component/features/take-assessments/TakeAssessments";

export class TakeAssessmentRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/take-assessments/:code"} component={TakeAssessment} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};