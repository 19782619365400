import axios from "axios";

import { assessmaxBaseurl, refresh } from "../settings/apiConfig";
import { ACCESS_TOKEN } from "../settings/auth";
import { getValue } from "../settings/token";

const getHeaders = (auth, data, token) => {
    const headers = {
        'Content-Type': 'application/json'
    }
    
    if ( auth  ) {

        if ( token ) {
            headers['Authorization'] = 'Bearer ' + token;
        }else {
            const bearerToken = getValue(ACCESS_TOKEN);
            
            if ( bearerToken ) {
                headers['Authorization'] = 'Bearer ' + bearerToken;
            }
        }
    }

    const config = { headers };
    
    if ( data ) {
        config['data'] = data;
    }

    return config;
};

export const getApi = (url, auth=true) => {
    return axios.get(assessmaxBaseurl + url, getHeaders(auth));
};

export const postApi = (url, data, auth=true) => {
    return axios.post(assessmaxBaseurl + url, data, getHeaders(auth));
};

export const putApi = (url, data, auth=true) => {
    return axios.put(assessmaxBaseurl + url, data, getHeaders(auth));
};

export const deleteApi = (url, data, auth=true) => {
    return axios.delete(assessmaxBaseurl + url, getHeaders(auth, data));
};

export const refreshApi = (token) => {
    return axios.get(assessmaxBaseurl + refresh, getHeaders(true, null, token));
};