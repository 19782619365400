import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { TextInput } from "../../../shared/form/FormInput";
import { Validators } from "../../../shared/utils/Validators";
import { departmentDetail, departmentAdd, departmentUpdate } from "../../../shared/settings/apiConfig";
import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";

import './DepartmentAddEdit.scss';

export const DeparmentAddEdit = (props) => {

    const isEdit = props.location.pathname.includes('edit');

    const [loading, setLoading] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [deparmentForm, setDepartmentForm] = useState({
        title: { value: '', error: '', valid: false, validators: [Validators.required()] },
        description: { value: '', error: '', valid: false, validators: [Validators.required()] },
    });

    useEffect(() => {
        if (isEdit) {
            setLoading(true);
            getApi(departmentDetail + props.match.params.departmentid).then(response => {
                setLoading(false);
                if (response.data.data.length > 0) {
                    let data = response.data.data[0];
                    setDepartmentForm(prevState => {
                        return Validators.validateAndSetAllFormControls(deparmentForm, data)
                    })
                } else {
                    console.log("Not found");
                }
            });
        }
    }, []);

    useEffect(() => {
        setFormValid(Validators.validateForm(deparmentForm));
    }, [deparmentForm]);

    const onInputChange = (type, event) => {
        setDepartmentForm(prevState => {
            return {
                ...prevState,
                [type]: Validators.validateFormControl(prevState[type], event.target.value)
            }
        })
    };

    const createDepartment = () => {
        const data = {
            title: deparmentForm.title.value,
            description: deparmentForm.description.value
        }

        postApi(departmentAdd, { data }).then(response => {
            props.history.goBack();
        }).catch(error => {
            console.log(error);
        })
    };

    const updateDepartment = () => {
        const data = {
            id: props.match.params.departmentid,
            title: deparmentForm.title.value,
            description: deparmentForm.description.value
        }

        putApi(departmentUpdate, { data }).then(response => {
            props.history.goBack();
        }).catch(error => {
            console.log(error);
        })
    };

    const cancelAndGoBack = () => {
        props.history.goBack();
    };

    return (
        <div className='full-width-height display-flex h-center v-center'>
            {
                loading ?
                    <img className='loader-img' src={`${process.env.PUBLIC_URL}/static/images/loader.svg`} alt="loader-icon" /> :
                    <div className='department-ae-container'>
                        <TextInput name={'title'} label={'Department Title'} placeholder={'Department Title'}
                            value={deparmentForm.title.value} error={deparmentForm.title.error} onChange={(event) => onInputChange('title', event)} />

                        <TextInput name={'description'} label={'Description'} placeholder={'Description'}
                            value={deparmentForm.description.value} error={deparmentForm.description.error} onChange={(event) => onInputChange('description', event)} />

                        <div className='d-flex align-items-center justify-content-center gap-3 mt-3'>
                            <Button onClick={cancelAndGoBack}>Cancel</Button>
                            <Button className='d-flex' onClick={isEdit ? updateDepartment : createDepartment} disabled={!formValid}>{isEdit ? 'Update' : 'Create'} {false ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</Button>
                        </div>
                    </div>
            }
        </div>
    )
}