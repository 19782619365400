import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { LocationList } from "../component/features/locations/LocationList";
import { LocationAdd } from "../component/features/locations/LocationAdd";

export class LocationRouter extends Component {

    render() {
        return (
            <Switch>
                <Route exact path={"/locations"} component={LocationList} />
                <Route exact path={[`/locations/add`, `/locations/edit/:locationid`]} component={LocationAdd} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};
