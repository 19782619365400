import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { locationAdd, locationDetail, locationUpdate, timeZoneList } from "../../../shared/settings/apiConfig";
import { SelectInput, TextInput } from "../../../shared/form/FormInput";
import { Validators } from "../../../shared/utils/Validators";
import { getApi, postApi, putApi } from "../../../shared/services/apiHelper";

import './Location.scss';

const locationStatus = [
    { option: "Active", value: "Active" },
    { option: "Inactive", value: "Inactive" }
]
export const LocationAdd = (props) => {
    const isEdit = props.location.pathname.includes('edit');

    const [locationForm, setLocationForm] = useState({
        title: { value: '', valid: false, error: '', validators: [Validators.required()] },
        description: { value: '', valid: false, error: '', validators: [] },
        contactname: { value: '', valid: false, error: '', validators: [Validators.required()] },
        email: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.email()] },
        phone: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.phoneNumberValid()] },
        website: { value: '', valid: false, error: '', validators: [Validators.required()] },
        about: { value: '', valid: false, error: '', validators: [Validators.required()] },
        city: { value: '', valid: false, error: '', validators: [Validators.required()] },
        country: { value: '', valid: false, error: '', validators: [Validators.required()] },
        state: { value: '', valid: false, error: '', validators: [Validators.required()] },
        zip: { value: '', valid: false, error: '', validators: [Validators.required()] },
        status: { value: '', valid: false, error: '', validators: [Validators.required()] },
        timezoneid: { value: '', valid: false, error: '', validators: [Validators.required()] },
    });

    const [saveLoading, setSaveLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [timeZoneData, setTimeZoneData] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        getApi(timeZoneList).then((response) => {
            let timeZoneData = response.data.data;
            setTimeZoneData(
                timeZoneData.map(elem => {
                    return { option: elem.zonename, value: elem.id }
                }));
        })
        if (isEdit) {
            setFormLoading(true);
            getApi(locationDetail + props.match.params.locationid).then((response) => {
                let data = response.data.data[0];
                if (data) {
                    setLocationForm(prevState => {
                        return Validators.validateAndSetAllFormControls(prevState, data)
                    })
                }
                setFormLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        setFormValid(Validators.validateForm(locationForm));
    }, [locationForm]);

    const onChangeInput = (type, event) => {
        setLocationForm(prevState => {
            return {
                ...prevState,
                [type]: Validators.validateFormControl(prevState[type], event.target.value)
            }
        })
    };

    const onCancel = () => {
        props.history.goBack();
    }

    const onSaveLocation = () => {
        setSaveLoading(true);
        let data = {
            "title": locationForm.title.value,
            "description": locationForm.description.value,
            "contactname": locationForm.contactname.value,
            "email": locationForm.email.value,
            "phone": locationForm.phone.value,
            "website": locationForm.website.value,
            "about": locationForm.about.value,
            "city": locationForm.city.value,
            "country": locationForm.country.value,
            "state": locationForm.state.value,
            "zip": locationForm.zip.value,
            "status": locationForm.status.value,
            "timezoneid": locationForm.timezoneid.value,
        };
        if (isEdit) {
            data["id"] = props.match.params.locationid
            putApi(locationUpdate, { data }).then((response) => {
                if (response.data.status == "success") {
                    props.history.goBack();
                }
                setSaveLoading(false);
            }).catch((error) => {
                setSaveLoading(false);
                errorMessage(error.message[0]);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            })
        } else {
            postApi(locationAdd, { data }).then((response) => {
                if (response.data.status == "success") {
                    props.history.goBack();
                }
                setSaveLoading(false);
            }).catch((error) => {
                setSaveLoading(false);
                setErrorMessage(error.message[0]);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            })
        }
    }

    return (
        <div className="display-flex h-center v-center mt-5 mb-5">
            {
                formLoading ?
                    <img className='loader-img' src={`${process.env.PUBLIC_URL}/static/images/loader.svg`} alt="loader-icon" /> :

                    <div className="location-form">
                        <TextInput name={'title'} label={'Title'} placeholder={'Title'}
                            value={locationForm.title.value} error={locationForm.title.error} onChange={(event) => onChangeInput('title', event)} />

                        <TextInput name={'description'} label={'Description'} placeholder={'Description'}
                            value={locationForm.description.value} error={locationForm.description.error} onChange={(event) => onChangeInput('description', event)} />

                        <TextInput name={'contactname'} label={'Contactname'} placeholder={'Contactname'}
                            value={locationForm.contactname.value} error={locationForm.contactname.error} onChange={(event) => onChangeInput('contactname', event)} />

                        <TextInput name={'email'} label={'Email'} placeholder={'Email'}
                            value={locationForm.email.value} error={locationForm.email.error} onChange={(event) => onChangeInput('email', event)} />

                        <TextInput name={'phone'} label={'Phone'} placeholder={'Phone'}
                            value={locationForm.phone.value} error={locationForm.phone.error} onChange={(event) => onChangeInput('phone', event)} />

                        <TextInput name={'website'} label={'Website'} placeholder={'Website'}
                            value={locationForm.website.value} error={locationForm.website.error} onChange={(event) => onChangeInput('website', event)} />

                        <TextInput name={'about'} label={'About'} placeholder={'About'}
                            value={locationForm.about.value} error={locationForm.about.error} onChange={(event) => onChangeInput('about', event)} />

                        <TextInput name={'city'} label={'City'} placeholder={'City'}
                            value={locationForm.city.value} error={locationForm.city.error} onChange={(event) => onChangeInput('city', event)} />

                        <TextInput name={'country'} label={'Country'} placeholder={'Country'}
                            value={locationForm.country.value} error={locationForm.country.error} onChange={(event) => onChangeInput('country', event)} />

                        <TextInput name={'state'} label={'State'} placeholder={'State'}
                            value={locationForm.state.value} error={locationForm.state.error} onChange={(event) => onChangeInput('state', event)} />

                        <TextInput name={'zip'} label={'Zip'} placeholder={'Zip'}
                            value={locationForm.zip.value} error={locationForm.zip.error} onChange={(event) => onChangeInput('zip', event)} />

                        <SelectInput name={'status'} label={'Status'} placeholder={'Status'}
                            value={locationForm.status.value} options={locationStatus} error={locationForm.status.error} onChange={(event) => onChangeInput('status', event)} />

                        <SelectInput name={'timezoneid'} label={'Timezone'} placeholder={'TimeZone'}
                            value={locationForm.timezoneid.value} options={timeZoneData} error={locationForm.timezoneid.error} onChange={(event) => onChangeInput('timezoneid', event)} />

                        <div className="error-message">{errorMessage}</div>

                        <div className="d-flex mt-5" style={{ gap: '10px' }}>
                            <button type="button" className="btn btn-outline-primary" onClick={onCancel}>Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={onSaveLocation} disabled={!formValid}>Save {saveLoading ? <Spinner className='ms-2' as="span" animation="border" role="status" aria-hidden="true" /> : ''}</button>
                        </div>
                    </div>
            }

        </div>
    )
}