import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { Home } from "../component/features/home/Home";
import { ClientRouter } from "./ClientRouter";
import { LocationRouter } from "./LocationRouter";
import { DepartmentRouter } from "./DepartmentRouter";
import { AssessmentRouter } from "./AssessmentRouter";
import { Profile } from "../component/profile/Profile";
import { RunAssessmentRouter } from "./RunAssessmentRouter";
import { TakeAssessmentRouter } from "./TakeAssessmentRouter";

export class AppRouter extends Component {
    
    render() {
        return (
            <div style={{ height: '100%', width: '88%', overflowX: 'hidden', overflowY: 'auto' }}>
                <Switch>
                    <Route exact path='/'>
                        <Redirect to="/home" />
                    </Route>
                    <Route exact path={"/home"} component={Home} />
                    <Route path={"/profile"} component={Profile} /> 
                    <Route path={"/locations"} component={LocationRouter} />
                    <Route path={"/departments"} component={DepartmentRouter} />
                    <Route path={"/clients"} component={ClientRouter} />
                    <Route path={"/assessments"} component={AssessmentRouter} />
                    <Route path={"/run-assessments"} component={RunAssessmentRouter} />
                    <Route path={"/take-assessments"} component={TakeAssessmentRouter} />
                    <Redirect to="/not-found" />
                </Switch>
            </div>
        )
    }
}

