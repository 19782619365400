import { configureStore } from '@reduxjs/toolkit';

import { userSlice, refreshTokenIntervalSlice } from './reducer';

export default configureStore({
    reducer: {
        user: userSlice.reducer,
        refreshTokenInterval: refreshTokenIntervalSlice.reducer
    }
});
