import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { AssessmentList } from "../component/features/assessments/AssessmentList";
import { AssessmentAddFn } from "../component/features/assessments/AssessmentAddFn";

export class AssessmentRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/assessments"} component={AssessmentList} />
                <Route exact path={"/assessments/create"} component={AssessmentAddFn} />
                <Route exact path={"/assessments/edit/:assessmentid"} component={AssessmentAddFn} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};