import store from '../../redux/store';
import { actions } from '../../redux/actions';

import { userDetail } from "./apiConfig";
import { getValue, setValue, clearValue, decodeToken, isTokenExpired } from './token';
import { getApi, refreshApi } from "../services/apiHelper";

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const isAuthorized = (logoutUser = false) =>  {
    let userLogin = getValue(ACCESS_TOKEN) ? true : false;

    if (logoutUser && userLogin) {
        logout(null, false);
        userLogin = false;
    }

    return userLogin;
};

export const isAuthenticated = async () => {
    try {
        if (!getValue(ACCESS_TOKEN) || !getValue(REFRESH_TOKEN) || isTokenExpired(REFRESH_TOKEN)) {
            logout(null, false);
        } else {
            // All valid. So refreshing token
            await refreshToken();
            await setUser();

            initiateRefreshInterval();
        }
    } catch(error) {
        // Some error while refreshing so removing the token
        logout(null, false);
    }
};

export const login = (token) => {
    // sets the user token in local storage
    setValue(ACCESS_TOKEN, token[ACCESS_TOKEN]);
    setValue(REFRESH_TOKEN, token[REFRESH_TOKEN]);

    initiateRefreshInterval();
}

export const logout = (history, navigate=true) => {
    // unset user from app
    store.dispatch(actions.setUser({}));

    const reduxState = store.getState();
    // clears the new access token get call interval
    if (reduxState['refreshTokenInterval']) {
        clearInterval(reduxState['refreshTokenInterval']);
        store.dispatch(actions.setRefreshTokenInterval(null));
    }

    // clears token details from local storage
    clearValue(ACCESS_TOKEN);
    clearValue(REFRESH_TOKEN);

    if (navigate) {
        history.push('/');
    }
}

export const setUser = async () => {
    try {
        let userId = null;
        const token = getValue(ACCESS_TOKEN);
        if (token) {
            const tokenData = decodeToken(token);
            userId = tokenData.user;
        };

        const response = await getApi(`${userDetail}/${userId}`, true);

        if (Array.isArray(response?.data?.data) && response.data.data.length > 0) {
            store.dispatch(actions.setUser(response.data.data[0]));
        }

        return response;
    } catch(error) {
    }
};

const refreshToken = async () => {
    const token = getValue(REFRESH_TOKEN);
    return new Promise((resolve, reject) => {
        if (token) {
            refreshApi(token).then((response) => {
                setValue(ACCESS_TOKEN, response.data.newToken);
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
        } else {
            reject(null);
        }
    });
};

const initiateRefreshInterval = () => {
    // Refreshing the token for every 20 mins
    const reduxState = store.getState();
    if (reduxState['refreshTokenInterval']) {
        clearInterval(reduxState['refreshTokenInterval']);
    }
    const intervalId = setInterval(() => {
        console.log("Refreshing token for every 20 mins");
        refreshToken();
    }, (20 * 60 * 1000));
    store.dispatch(actions.setRefreshTokenInterval(intervalId));
};
