import { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import { RunAssessmentList } from "../component/features/run-assessments/RunAssessmentList";
import { RunAssessmentsAdd } from "../component/features/run-assessments/RunAssessmentsAdd";

export class RunAssessmentRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Route exact path={"/run-assessments"} component={RunAssessmentList} />
                <Route exact path="/run-assessments/add" component={RunAssessmentsAdd} />
                <Redirect to="/not-found" />
            </Switch>
        )
    }
};