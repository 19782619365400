import * as moment from 'moment-timezone';

import React, { createRef, useState } from "react";
import { Button, Row } from "react-bootstrap";

import { Datatable } from "../../../shared/datatable/Datatable";
import { departmentList } from "../../../shared/settings/apiConfig";
import { TextInput } from "../../../shared/form/FormInput";

import { Validators } from "../../../shared/utils/Validators";

const runAssessmentFilters = {
    'title': { field: 'title', value: '', op: 'LIKE', 'join': 'AND' },
    'description': { field: 'description', value: '', op: 'LIKE', 'join': 'AND' },
};

export const DepartmentList = (props) => {
    
    const dataTable = createRef();

    const customDate = (dataValue) => {
        if (dataValue) {
            let date = moment.utc(dataValue).local();
            return `${date.format('MM/DD/YYYY hh:mm A')} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
        } else
            return '- - -';
    };

    const [departmentSearchForm, setRunAssessmentSearchForm] = useState({
        'title': { value: '', valid: true, error: '', validators: [] },
        'description': { value: '', valid: true, error: '', validators: [] },
    });

    const [config, setConfig] = useState({
        name: 'department',
        url: departmentList,
        columns: [
            {
                name: '', property: '', columnType: 'actions', width: '150px',
                actions: {
                    header: <Button className="save-button" onClick={() => { navigateToCreate() }}>+ Create</Button>,
                    columns: [
                        { type: 'edit', action: (id) => { navigateToEdit(id) } }
                    ]
                }
            },
            { name: 'Department Title', property: 'title', sort: true, width: '200px' },
            { name: 'Description', property: 'description', sort: true, width: '250px' },
            { name: 'Created Date', property: 'createddate', sort: true, width: '250px', customColumn: customDate },
            { name: 'Modified Date', property: 'modifieddate', sort: true, width: '250px', customColumn: customDate }
        ],
        field: ["id", "title", "description", "status", "createddate", "modifieddate"],
        filter: [],
        sort: [{ "field": "title", "direction": "asc"}],
        pagination: true,
    });

    const navigateToCreate = () => {
        props.history.push('/departments/create');
    }

    const navigateToEdit = (id) => {
        props.history.push('/departments/edit/' + id);
    };

    const onSearch = () => {
        let filters = [];
        const allFilters = {...runAssessmentFilters};
        const controlKeys = Object.keys(departmentSearchForm);
        controlKeys.forEach(control => {
            if (departmentSearchForm[control].value) {
                filters.push({...allFilters[control], value: departmentSearchForm[control].value});
            }
        });

        if (filters.length > 0) {
            dataTable.current.searchData(filters);
        }
    };

    const onClear = () => {
        setRunAssessmentSearchForm(prevState => {
            const formData = {...prevState};
            const keys = Object.keys(formData);
            keys.forEach(key => {
                formData[key].value = "";
            });

            return formData;
        });

        dataTable.current.searchData([]);
    };

    const setValue = (controlName, event) => {
        setRunAssessmentSearchForm(prevState => {
            return {
                ...prevState,
                [controlName]: Validators.validateFormControl(departmentSearchForm[controlName], event.target.value)
            }
        });
    }

    return (
        <div className="department-list">
            <div className="d-flex search-container">
                <Row>
                    <TextInput name={'title'} label={'Department Title'} placeholder={'Department Title'}
                        value={departmentSearchForm.title.value} onChange={(event) => setValue('title', event)} />
                    <TextInput name={'description'} label={'Description'} placeholder={'Description'}
                        value={departmentSearchForm.description.value} onChange={(event) => setValue('description', event)} />
                </Row>
                <div className="d-flex" style={{ gap: '10px', justifyContent: 'flex-end' }}>
                    <button type="button" className="btn btn-outline-primary" onClick={onClear}>Clear All</button>
                    <button type="button" className="btn btn-primary" onClick={onSearch}>Search</button>
                </div>
            </div>
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
        </div>
    );
};
